define("backend/models/buffered-model", ["exports", "backend/utils/buffer-proxy", "ember-data", "backend/mixins/buffer-states-mixin", "backend/mixins/buffer-dependencies-mixin"], function (_exports, _bufferProxy, _emberData, _bufferStatesMixin, _bufferDependenciesMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_bufferStatesMixin.default, _bufferDependenciesMixin.default, {
    // ------------------- Manage buffered attachments -------------------------

    /**
     * Add buffered attachment
     */
    addAttachment: function addAttachment(uploadUrl) {
      var record = this.get("store").createRecord("attachment", {
        uploadUrl: uploadUrl
      });
      var buffer = (0, _bufferProxy.default)(record);
      this.get("buffers").attachments.pushObject(buffer);
    },

    /**
     * Remove buffered attachment
     */
    removeAttachment: function removeAttachment(buffer) {
      this.get("buffers").attachments.removeObject(buffer);
      buffer.discardChanges();
      this.get("deletedAttachments").pushObject(buffer.content);
    },

    /**
     * List of attachments to be deleted
     */
    deletedAttachments: Ember.computed(function () {
      return [];
    }),

    /**
     * Clear list of attachments to be deleted
     */
    clearAttachments: function clearAttachments() {
      this.set("deletedAttachments", null);
    },
    // --------------------- Manage buffered departments -----------------------
    addDepartment: function addDepartment() {
      var record = this.get("store").createRecord("department");
      var buffer = (0, _bufferProxy.default)(record);
      return this.get("buffers").departments.pushObject(buffer);
    },
    removeDepartment: function removeDepartment(buffer) {
      this.get("buffers").departments.removeObject(buffer);
      buffer.discardChanges();
    },
    saveDepartment: function saveDepartment(department) {
      console.log('buffered-model saveDepartment method was called with department:', department);
      var departments = this.get("buffers").departments;
      console.log('buffered-model departments:', departments); // Extract department ID

      var departmentId = department.get ? department.get('id') : department.content.id;
      console.log('buffered-model department ID:', departmentId);

      if (!departmentId) {
        console.error('No valid ID found for the department. Update request cannot be sent.');
        return Ember.RSVP.reject('No ID found for department.');
      }

      return this.store.findRecord('department', departmentId).then(function (departmentModel) {
        console.log('buffered-model fetched Ember Data department:', departmentModel);
        return new Ember.RSVP.Promise(function (resolve, reject) {
          if (!department.hasBufferedChanges) {
            console.log("No changes to save for department ID ".concat(departmentId, "."));
            return resolve();
          }

          console.log("Saving department with ID ".concat(departmentId, " and buffered changes:"), department.buffer);
          departmentModel.setProperties(department.buffer);
          departmentModel.save().then(function () {
            console.log("Department with ID ".concat(departmentId, " saved successfully."));
            department.set('isSaved', true);
            setTimeout(function () {
              department.set('isSaved', false);
            }, 3000);
            resolve();
          }).catch(function (error) {
            console.error("Error saving department with ID ".concat(departmentId, ":"), error);
            reject(error);
          });
        });
      }).catch(function (error) {
        console.error("Error fetching department model for ID ".concat(departmentId, ":"), error);
      });
    },
    // ----------------------- Managed buffered contacts -----------------------

    /**
     * Add a buffered contact
     */
    addContact: function addContact() {
      var buffer, record;
      record = this.get("store").createRecord("contact");
      buffer = (0, _bufferProxy.default)(record);
      return this.get("buffers").contacts.pushObject(buffer);
    },
    saveContact: function saveContact(contact) {
      console.log('buffered-model saveContact method was called with contact:', contact);
      var contactId = contact.get ? contact.get('id') : contact.content.client || contact.content.lead;
      console.log('buffered-model contact ID:', contactId);

      if (!contactId) {
        console.error('No valid ID found for the contact. Update request cannot be sent.');
        return Ember.RSVP.reject('No ID found for contact.');
      }

      return this.store.findRecord('contact', contactId).then(function (contactModel) {
        console.log('buffered-model fetched Ember Data contact:', contactModel);
        return new Ember.RSVP.Promise(function (resolve, reject) {
          if (!contact.hasBufferedChanges) {
            console.log("No changes to save for contact ID ".concat(contactId, "."));
            return resolve();
          }

          console.log("Saving contact with ID ".concat(contactId, " and buffered changes:"), contact.buffer);
          contactModel.setProperties(contact.buffer);
          contactModel.save().then(function () {
            console.log("Contact with ID ".concat(contactId, " saved successfully."));
            contact.set('isSaved', true); // ✅ Ensure the model is marked as "clean" after saving
            // ✅ Clear the buffer manually to remove any pending changes

            if (contact.buffer) {
              Object.keys(contact.buffer).forEach(function (key) {
                contact.buffer[key] = contactModel.get(key); // Sync buffer with saved data
              });
            }

            setTimeout(function () {
              contact.set('isSaved', false);
            }, 3000);
            resolve(contactModel);
            contactModel.set('hasBufferedChanges', false); // If used for tracking

            contactModel.rollbackAttributes(); // Clear any tracked changes
            //this.store.peekRecord('contact', contactId)?.notifyPropertyChange('id'); // Force update
            //var contacts = this.get("buffers").contacts;
            //contacts.clear();
            //const selected = this.get('selected');
            //selected.set('hasJustSaved', true);
            //this.sendAction('saveCompleteAction');
          }).catch(function (error) {
            console.error("Error saving contact with ID ".concat(contactId, ":"), error); // Rollback only if the save fails

            contactModel.rollbackAttributes();
            reject(error);
          });
        });
      }).catch(function (error) {
        console.error("Error fetching contact model for ID ".concat(contactId, ":"), error);
      });
    },

    /*
        saveContact(contact) {
          console.log('buffered-model saveContact method was called with contact:', contact);
        
          const contactId = contact.get ? contact.get('id') : contact.content.client || contact.content.lead;
          console.log('buffered-model contact ID:', contactId);
        
          if (!contactId) {
            console.error('No valid ID found for the contact. Update request cannot be sent.');
            throw new Error('No ID found for contact.');
          }
        
          const url = `${ENV.host}/contacts/${contactId}`;
          console.log('buffered-model saveContact url:', url);
        
      
            this.get('session').authorize('authorizer:hm-token', (headerName, headerValue) => {
              const headers = {};
              headers[headerName] = headerValue;
        
              this.get('ajax')
                .request(url, {
                  method: 'PUT',
                  data: contact.buffer, // Send only modified fields
                  contentType: 'application/json',
                  dataType: 'json',
                  headers
                })
                .then(() => {
                  console.log(`Contact with ID ${contactId} saved successfully.`);
                  contact.set('isSaved', true);
        
                  // Reset the isSaved flag after 3 seconds
                  setTimeout(() => {
                    contact.set('isSaved', false);
                  }, 3000);
        
                  resolve();
                })
                .catch(({ payload: { error } }) => {
                  console.error(`Error saving contact with ID ${contactId}:`, error);
                  this.set('errorMessage', error);
                  reject(error);
                });
            });
       
        },
        */
    saveContacts: function saveContacts() {
      console.log('buffered-model saveContacts method was called'); //var contacts = this.get('selected.contacts');

      var contacts = this.get("buffers").contacts;
      console.log('buffered-model contacts:', contacts);
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!contacts || contacts.get('length') === 0) {
          return resolve();
        }

        var promises = [];
        contacts.forEach(function (record) {
          return promises.pushObject(record.save());
        }); // TODO: Add Flash

        Ember.RSVP.Promise.all(promises).then(function (contacts) {
          return resolve(contacts);
        }).catch(reject);
      });
    },

    /**
     * Remove a buffered contact
     */
    removeContact: function removeContact(buffer) {
      console.log('buffered-model removeContact');
      this.get("buffers").contacts.removeObject(buffer);
      buffer.discardChanges();
      this.get("deletedContacts").pushObject(buffer.content);
    },

    /**
     * List of contacts to be deleted
     */
    deletedContacts: Ember.computed(function () {
      return [];
    }),

    /**
     * Clear list of contacts to be deleted
     */
    clearContacts: function clearContacts() {
      return this.set("deletedContacts", null);
    }
  });

  _exports.default = _default;
});