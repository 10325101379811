define("backend/pods/components/hm-simple-forms/create-offer/component", ["exports", "backend/components/route-component", "backend/db-cache/countries", "backend/utils/dropdown-options/sales-closing-months"], function (_exports, _routeComponent, _countries, _salesClosingMonths) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Offer = Ember.Object.extend({
    owner: null,
    department: null,
    contactName: null,
    contactPhone: null,
    contactEmail: null,
    name: null,
    devices: null,
    configurations: Ember.computed(function () {
      return [];
    }),
    language_packages: Ember.computed(function () {
      return [];
    }),
    notes: null,
    closing_probability: null,
    closing_month: null,
    save: function save(store, options) {
      if (!this.get('owner')) {
        return Ember.RSVP.reject({
          errors: [{
            error: "Select a lead"
          }]
        });
      }

      if (!this.get('department')) {
        return Ember.RSVP.reject({
          errors: [{
            error: "Select a department"
          }]
        });
      }

      var _this$getProperties = this.getProperties('contactName', 'contactPhone', 'contactEmail'),
          contactName = _this$getProperties.contactName,
          contactPhone = _this$getProperties.contactPhone,
          contactEmail = _this$getProperties.contactEmail;

      if (!(contactName || contactPhone || contactEmail)) {
        return Ember.RSVP.reject({
          errors: [{
            error: "Select or enter a contact"
          }]
        });
      }

      var configurations = this.get('configurations');

      if (!configurations || configurations.length === 0) {
        return Ember.RSVP.reject({
          errors: [{
            error: "Select a configuration"
          }]
        });
      }

      var data = JSON.parse(JSON.stringify(this));
      data.owner = {
        id: this.get('owner.id'),
        displayName: this.get('owner.displayName')
      };
      var country = _countries.default.findBy('id', this.get('owner.address.country')) ? _countries.default.findBy('id', this.get('owner.address.country')).displayName : this.get('owner.address.country');
      data.owner_address = [[this.get('owner.address.address1'), this.get('owner.address.address2'), this.get('owner.address.address3')].join(' '), [this.get('owner.address.zip'), this.get('owner.address.city')].join(' '), [country, this.get('owner.address.state')].join(' ')].join('\n');
      data.language_packages = this.get('language_packages').map(function (label) {
        return JSON.parse(JSON.stringify(options.languagePackages.findBy('displayName', label)));
      });
      data.configurations = configurations.map(function (label) {
        return JSON.parse(JSON.stringify(options.configurationOptions.findBy('displayName', label)));
      });
      return store.adapterFor('application').createOffer(data);
    }
  });

  var _default = _routeComponent.default.extend({
    isPending: false,
    options: {
      devices: [Ember.Object.create({
        id: 1,
        displayName: '1 VG'
      }), Ember.Object.create({
        id: 2,
        displayName: '2 VG'
      }), Ember.Object.create({
        id: 3,
        displayName: '3 VG'
      }), Ember.Object.create({
        id: 4,
        displayName: '4 VG'
      }), Ember.Object.create({
        id: 5,
        displayName: '5 VG'
      }), Ember.Object.create({
        id: 6,
        displayName: '1 hypnoVR'
      }), Ember.Object.create({
        id: 7,
        displayName: '2 hypnoVR'
      }), Ember.Object.create({
        id: 8,
        displayName: '3 hypnoVR'
      }), Ember.Object.create({
        id: 9,
        displayName: '4 hypnoVR'
      }), Ember.Object.create({
        id: 10,
        displayName: '5 hypnoVR'
      })],
      languagePackages: [Ember.Object.create({
        id: 'de',
        displayName: 'German'
      }), Ember.Object.create({
        id: 'en',
        displayName: 'English'
      }), Ember.Object.create({
        id: 'fr',
        displayName: 'French'
      })],
      closingProbabilities: [Ember.Object.create({
        id: '10%',
        displayName: '10% low'
      }), Ember.Object.create({
        id: '40%',
        displayName: '40% let\'s see'
      }), Ember.Object.create({
        id: '60%',
        displayName: '60% optimistic'
      }), Ember.Object.create({
        id: '90%',
        displayName: '90% almost signed'
      })],
      closingMonths: (0, _salesClosingMonths.createClosingMonthOptionsForNewOffers)(),
      configurationOptions: [Ember.Object.create({
        id: 'rent_yearly_1',
        displayName: 'Option 1: Anmietung mit 12 monatiger Laufzeit'
      }), Ember.Object.create({
        id: 'rent_yearly_3',
        displayName: 'Option 2: Anmietung mit 36 monatiger Laufzeit   '
      }), Ember.Object.create({
        id: 'buy_excl_content',
        displayName: 'Option 3: Ankauf Hardware zuzüglich gewerblicher Filmlizenzen'
      }), Ember.Object.create({
        id: 'buy_incl_content',
        displayName: 'Option 4: Ankauf Hardware inklusive gewerblicher Filmlizenzen  '
      }), Ember.Object.create({
        id: 'pillow',
        displayName: 'Option 5: Spezielles Lagerungskissen für Bauch- und Seitenlage mit Aussparung für Videobrille um 149 €'
      }), Ember.Object.create({
        id: 'tablet',
        displayName: 'Option 6: EasyMovie Tablet'
      })]
    },
    init: function init() {
      this._super.apply(this, arguments);

      var offer = Offer.create({
        name: this.get('currentUser.fullName') || '?'
      });

      if (this.data.owner) {
        offer.set('owner', this.data.owner);
        offer.set('department', this.data.owner.get('departments.firstObject.id'));
      }

      this.set('offer', offer);
    },
    sendOffer: function sendOffer() {
      var _this = this;

      this.set('isPending', true);
      this.get('flashes').clear();
      this.get('offer').save(this.get('store'), this.get('options')).then(function () {
        var flashes = _this.get('flashes');

        var succFlash = {
          alert: 'Offer has been sent!'
        };
        flashes.pushObject(succFlash);
        Ember.run.later(function () {
          if (flashes) {
            flashes.removeObject(succFlash);
          }
        }, 10000);
      }).catch(function (xhr) {
        if (xhr && xhr.errors) {
          var flashes = _this.get('flashes');

          var errFlashes = xhr.errors;
          flashes.pushObjects(errFlashes);
          Ember.run.later(function () {
            if (flashes) {
              flashes.removeObjects(errFlashes);
            }
          }, 10000);
        } else {
          throw xhr;
        }
      }).finally(function () {
        return _this.set('isPending', false);
      });
    },
    ownerContacts: Ember.computed('offer.owner.id', function () {
      return this.get('offer.owner.contacts');
    }),
    customContacts: Ember.computed(function () {
      return [];
    }),
    contacts: Ember.computed.union('ownerContacts', 'customContacts'),
    actions: {
      submit: function submit() {
        return this.sendOffer();
      },
      selectOwner: function selectOwner(owner) {
        this.get('offer').set('department', owner.get('departments.firstObject.id'));
      },
      selectContact: function selectContact(contact) {
        if (contact) {
          var _contact$getPropertie = contact.getProperties('phone', 'email'),
              phone = _contact$getPropertie.phone,
              email = _contact$getPropertie.email;

          this.get('offer').setProperties({
            contactPhone: phone,
            contactEmail: email
          });
        }
      },
      createContact: function createContact(name) {
        if (name) {
          this.get('customContacts').pushObject(Ember.Object.create({
            displayName: name
          }));
          this.get('offer').setProperties({
            contactName: name,
            contactPhone: null,
            contactEmail: null
          });
        }
      }
    }
  });

  _exports.default = _default;
});